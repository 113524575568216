import type { NextPage } from 'next';
import React, { useEffect, useState } from 'react';
import { Authentication, useAuth } from 'context/AuthContext';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import { FirebaseError } from 'firebase/app';
import { ROUTES } from 'common/constants';
import Link from 'next/link';
import { ClientHr } from 'components/ClientHr';
import ContactImage from 'public/assets/bg-contact.png';
import { Button, Form, Input, Typography } from 'antd';
import { GeneralLayout } from 'components/layouts/GeneralLayout';
import { UserDTO, UserRole } from 'dtos';
import UserService from 'services/User';
import Head from 'next/head';

const schema = yup.object().shape({
  email: yup.string().email('Must be a valid email').required('Email is required'),
  password: yup.string().required('Password is required').min(8, 'Password must be at least 8 characters'),
});

type LoginData = Authentication;

const Login: NextPage = () => {
  const router = useRouter();
  const { isAuthenticated, login, handleTwoFactorSubmit } = useAuth();
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [twoFactor, setTwoFactor] = useState<boolean>(false);
  const [twoFactorCode, setTwoFactorCode] = useState<string>('');

  const handleTwoFactor = async (): Promise<void> => {
    if (!twoFactorCode) return setError('Two factor code is required');

    setError(null);
    setIsLoading(true);

    return await handleTwoFactorSubmit(twoFactorCode)
      .then(UserService.getMe)
      .then((user: UserDTO) => {
        setIsLoading(false);
        if (user.role === UserRole.ADMIN) {
          router.push(ROUTES.ADMIN_DASHBOARD);
        } else {
          router.push(ROUTES.USER_DASHBOARD);
        }
      })
      .catch((err: FirebaseError) => {
        setError(err.message);
        setIsLoading(false);
      });
  }

  const handleLogin = async (payload: LoginData): Promise<unknown> => {
    setError(null);
    setIsLoading(true);

    return await login({ email: payload.email, password: payload.password })
      .then(UserService.getMe)
      .then((user: UserDTO) => {
        setIsLoading(false);
        if (user.role === UserRole.ADMIN) {
          router.push(ROUTES.ADMIN_DASHBOARD);
        } else {
          router.push(ROUTES.USER_DASHBOARD);
        }
      })
      .catch((err: FirebaseError) => {
        console.log('🦑', err);
        if(err.code === 'auth/wrong-password') {
          setError('Invalid email or password');
        } else {
          setError(err.message);
        }

        if (err.code === 'auth/multi-factor-auth-required') {
          setTwoFactor(true);
        }

        setIsLoading(false);
      });
  };

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<LoginData>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (isAuthenticated) {
      router.push(ROUTES.USER_DASHBOARD);
    }
  }, [isAuthenticated, router]);

  return (
    <GeneralLayout
      className="bg-main"
      contentClassName="text-white px-4 py-24 md:py-52"
      skipContainer
      navbarProps={{ className: 'z-10 absolute top-0 w-full', transparent: true }}
      contentStyle={{
        background: `url(${ContactImage.src}) center center / cover no-repeat fixed`
      }}
    >
      <Head>
        <title>Login - Valley Insurance</title>
      </Head>
      <div className="text-center">
        <h2 className="text-4xl md:text-5xl">Log in</h2>
        <ClientHr className="mx-auto my-4"/>
      </div>

      {twoFactor ? (
        <form className="max-w-xl bg-white/20 mx-auto mt-2 md:mt-10 p-8 rounded-md" onSubmit={(e) => {
          e.preventDefault();
          handleTwoFactor();
        }}>
          <p className="text-center text-white pb-4 text-lg">
            Two factor authentication is required.<br/>
            Please type in the code from the SMS message.
          </p>
          <Input
            className="w-full"
            onChange={(e) => setTwoFactorCode(e.target.value)}
            placeholder="Enter two factor sms code"
          />

          <Button
            className="w-full mt-4"
            loading={isLoading}
            type="primary"
            htmlType='submit'
          >
            Submit the code
          </Button>
        </form>
        ) : (
        <div className="max-w-xl bg-white/20 mx-auto mt-2 md:mt-10 p-8 rounded-md">
          <div className='flex justify-center'>
            <div id='recaptcha-container-id'/>
          </div>

          <Form
            className='white-labels'
            name="login-form"
            onFinish={handleSubmit(handleLogin)}
            autoComplete="off"
            layout="vertical"
            requiredMark="optional"
          >
            <Form.Item required name="email" label="Email*">
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input onChange={field.onChange} value={field.value} placeholder="john@doe.com" autoComplete="email"/>
                )}
              />
              <Typography.Text type="danger">{errors.email?.message}</Typography.Text>
            </Form.Item>

            <Form.Item required name="password" label="Password*">
              <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input type='password' onChange={field.onChange} value={field.value} placeholder="******" autoComplete="password"/>
                )}
              />
              <Typography.Text type="danger">{errors.password?.message}</Typography.Text>
            </Form.Item>

            <div className="text-center text-white mt-4">
              <Button htmlType="submit" loading={isLoading} type="primary">
                Log in
              </Button>
              {error && <p className="text-red-500 text-center mt-3">{error}</p>}
              <div className="mt-4">
                Don&apos;t have an account? <Link href={ROUTES.SIGNUP} className="text-accent underline">Sign up</Link>
              </div>
              <div className="mt-1 text-xs">
                Forgot your password? <Link href={ROUTES.RESET_PASSWORD} className="text-accent underline">Click here</Link>
              </div>
            </div>
          </Form>
        </div>
      )}
    </GeneralLayout>
  );
};

export default Login;
